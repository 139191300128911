import React from "react";
import IMG4 from '../../assets/nodejs.png'
import './backend.css';

const data = [
    {
        id: 1,
        image: IMG4,
        title: 'RestApi - Mongo',
        description: 'Esta es una api de autenticación de loggeo basada en Typescript conectada con una base de datos no relacional en este caso MongoDB. Adicionalmente se le incorpora un CRUD de productos. ',
        github: 'https://github.com/MatiasNZamora/resApi-MongoDB.git',
        demo: '',        
        // tech1: '# NodeJs',
        // tech2: '| TS',
        // tech3: '| MongoDB',
        // tech4: '| Express',
        // tech5: '| JWT',
        tech: '# NodeJs | TS | MongoDB | Express | JWT'
    },
    {
        id: 2,
        image: IMG4,
        title: 'Sistema - NOC',
        description: 'Esta es un Sistema NOC o (Network Operations Center) basado en NodeJS y TypeScript que permite monitorear procesos a los cuales se le asignen anteriormente. "En este momento se encuentra en desarrollo." ',
        github: 'https://github.com/MatiasNZamora/Sistem-NOC.git',
        demo: '',
        tech: '# NodeJs | TS-Node | Cron'
    },
];


const Backend = () => {
    return (
        <section>
            <h2> Backend </h2>
            <div className="container">
                <div classNameName="container backend__container">
                    {
                        data.map(({ id, image, title, github, demo, tech, description }) => {
                            return (
                                <article key={id} className='backend__item'>
                                    <div className="backend__item-image">
                                        <img src={image} alt={title} />
                                    </div>
                                    <div className="backend__item-cta">
                                        <h3>{title}</h3>
                                        <p>{description}</p>
                                        <div className="tech">
                                            <p>{tech}</p>
                                            {/* <p className="ts">{tech2}</p> */}

                                        </div>
                                        <div>
                                            {/* <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a> */}
                                            <a href={github} className='btn' target='_blank'> Github </a>
                                            <br />
                                        </div>
                                    </div>
                                </article>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    );
}


export default Backend;

